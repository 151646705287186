






















































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem1LDELN4FSProcedure1',
  components: {TableInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        rows: [
          {
            chemicalName: 'Chemical Name',
            formula: 'Formula',
            molarMass: 'Molar Mass (g/mol)',
            physicalStates: 'Physical States (s, l, g, aq)',
            mass: 'Mass (g)',
            moles: 'Moles',
            volume: 'Volume (mL)',
            concentration: 'Concentration (units) or Density (g/mL)',
            constants: 'Constants',
          },
          {
            chemicalName: '',
            formula: '',
            molarMass: '',
            physicalStates: '',
            mass: '',
            moles: '',
            volume: '',
            concentration: '',
            constants: '',
          },
        ],
        supplyList: '',
      },
      attachments: [] as File[],
      columns: [
        {label: 'Chemical Name', component: STextarea, prop: 'chemicalName', width: '15%'},
        {label: 'Formula', component: STextarea, prop: 'formula', width: '10%'},
        {label: 'Molar Mass (g/mol)', component: STextarea, prop: 'molarMass', width: '10%'},
        {
          label: 'Physical States (s, l, g, aq)',
          component: STextarea,
          prop: 'physicalStates',
          width: '10%',
        },
        {label: 'Mass (g)', component: STextarea, prop: 'mass', width: '10%'},
        {label: 'Moles', component: STextarea, prop: 'moles', width: '10%'},
        {label: 'Volume (mL)', component: STextarea, prop: 'volume', width: '10%'},
        {
          label: 'Concentration (units) or Density (g/mL)',
          component: STextarea,
          prop: 'concentration',
          width: '15%',
        },
        {label: 'Constants', component: STextarea, prop: 'constants', width: '10%'},
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
